<template>
  <div>
    <form @submit.prevent="submitForm">
      <div class="form-control mb-10" :class="{ invalid: !bodyData.isValid }">
        <label for="" class="block text-sm font-medium text-gray-700"
          >Body</label
        >
        <div class="mt-1">
          <editor
            id="body"
            v-model.trim="bodyData.val"
            @blur="clearValidity('bodyData')"
            api-key="no-api-key"
            initial-value=""
            :init="{
              images_upload_url: imagesUploadUrl,
              images_upload_handler: imageUploadHandler,
              convert_urls: false,
              height: 500,
              menubar: false,
              /*
            menu: {
              file: {
                title: 'File',
                items: 'newdocument restoredraft | preview | print ',
              },
              edit: {
                title: 'Edit',
                items: 'undo redo | cut copy paste | selectall | searchreplace',
              },
              view: {
                title: 'View',
                items:
                  'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen',
              },
              insert: {
                title: 'Insert',
                items:
                  'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
              },
              format: {
                title: 'Format',
                items:
                  'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat',
              },
              tools: {
                title: 'Tools',
                items: 'spellchecker spellcheckerlanguage | code wordcount',
              },
              table: {
                title: 'Table',
                items: 'inserttable | cell row column | tableprops deletetable',
              },
              help: { title: 'Help', items: 'help' },
            },
			*/
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              /*
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help',
            */
              toolbar:
                'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
                'forecolor backcolor emoticons | help',
            }"
          />
        </div>
        <p v-if="!bodyData.isValid" class="error-prompt">
          Body can't be empty.
        </p>
      </div>
      <div class="form-control mb-10" :class="{ invalid: !titleData.isValid }">
        <label for="title" class="block text-sm font-medium text-gray-700"
          >Title</label
        >
        <div class="mt-1">
          <input
            type="text"
            id="title"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            v-model.trim="titleData.val"
            @blur="clearValidity('titleData')"
          />
        </div>
        <p v-if="!titleData.isValid" class="error-prompt">
          {{ titleData.errorMsg || "Title can't be blank." }}
        </p>
      </div>
      <div
        class="form-control mb-10"
        :class="{ invalid: !descriptionData.isValid }"
      >
        <label for="description" class="block text-sm font-medium text-gray-700"
          >Description</label
        >
        <div class="mt-1">
          <textarea
            id="description"
            rows="5"
            maxlength="500"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            v-model.trim="descriptionData.val"
            @blur="clearValidity('descriptionData')"
          ></textarea>
        </div>
        <p v-if="!descriptionData.isValid" class="error-prompt">
          Description can't be blank.
        </p>
      </div>
      <div class="form-control mb-10">
        <div class="flex justify-between mb-1">
          <label for="category" class="block text-sm font-medium text-gray-700"
            >Category</label
          >
          <button class="cursor-pointer" @click.prevent="openCategoryDialog">
            Edit categories
          </button>
        </div>
        <select
          v-if="fetchCategoriesSuccess"
          id="category"
          v-model="category.val"
          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        >
          <option
            v-for="category of categories"
            :key="category"
            :value="category.id"
          >
            {{ category.name }}
          </option>
        </select>
        <div v-if="fetchCategoriesLoading">
          <fa-icon icon="spinner" spin />
        </div>
        <div v-if="fetchCategoriesError">
          <p class="text-red-700 font-semibold text-sm">
            Could not fetch categories
          </p>
        </div>
      </div>
      <div
        class="form-control mb-10"
        :class="{ invalid: !readingTimeData.isValid }"
      >
        <label for="readingTime" class="block text-sm font-medium text-gray-700"
          >Reading time</label
        >
        <div class="mt-1">
          <input
            type="text"
            id="readingTime"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            v-model.trim="readingTimeData.val"
            @blur="clearValidity('readingTimeData')"
          />
        </div>
        <p v-if="!readingTimeData.isValid" class="error-prompt">
          Reading time can't be blank.
        </p>
      </div>
      <div class="form-control mb-10" :class="{ invalid: !authorData.isValid }">
        <label for="author" class="block text-sm font-medium text-gray-700"
          >Author</label
        >
        <div class="mt-1">
          <input
            type="text"
            id="author"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            v-model.trim="authorData.val"
            @blur="clearValidity('authorData')"
          />
        </div>
        <p v-if="!authorData.isValid" class="error-prompt">
          Author can't be blank.
        </p>
      </div>

      <div>
        <p v-if="!formIsValid" class="error-prompt">
          Please fix the input errors and try again.
        </p>
      </div>
      <div class="pt-5">
        <div class="flex justify-end">
          <button
            type="button"
            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click.prevent="$router.go(-1)"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {{ submitBtnText }}
          </button>
        </div>
      </div>
    </form>
    <CategoryDialog
      :show="isCategoryDialogOpen"
      :categories="categories"
      @close="closeCategoryDialog"
    />
  </div>
</template>

<script>
import 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
import 'tinymce/icons/default'
import 'tinymce/skins/ui/oxide/skin.css'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/print'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/help'
import 'tinymce/plugins/wordcount'
import { useQuery } from 'vue-query'
import { fetchCategories } from '@/api/categoryApi'
import CategoryDialog from './category/CategoryDialog.vue'
import { useToggle } from '@/composables'
import { uploadImage } from '@/api/blogApi'
export default {
  components: {
    editor: Editor,
    CategoryDialog,
  },
  props: {
    mode: {
      type: String,
      default: '', // 'new' or 'update'
    },
    id: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    categoryId: {
      type: Number,
      default: 1,
    },
    description: {
      type: String,
      default: '',
    },
    readingTime: {
      type: String,
      default: '',
    },
    author: {
      type: String,
      default: 'Jonathan',
    },
    datePosted: {
      type: String,
      default: '',
    },
  },
  emits: ['save-data'],
  setup() {
    const {
      state: isCategoryDialogOpen,
      open: openCategoryDialog,
      close: closeCategoryDialog,
    } = useToggle(false)
    const {
      data: categories,
      isLoading: fetchCategoriesLoading,
      isError: fetchCategoriesError,
      isSuccess: fetchCategoriesSuccess,
    } = useQuery(['categories'], () => fetchCategories(), {
      refetchOnWindowFocus: false,
    })
    return {
      categories,
      fetchCategoriesLoading,
      fetchCategoriesError,
      fetchCategoriesSuccess,
      isCategoryDialogOpen,
      openCategoryDialog,
      closeCategoryDialog,
      imagesUploadUrl: `${process.env.VUE_APP_SERVER_URL}/api/image/upload.php`,
    }
  },
  data() {
    return {
      bodyData: {
        val: this.body,
        isValid: true,
      },
      titleData: {
        val: this.title,
        isValid: true,
        errorMsg: null,
      },
      descriptionData: {
        val: this.description,
        isValid: true,
      },
      category: {
        val: this.categoryId,
        isValid: true,
      },
      readingTimeData: {
        val: this.readingTime,
        isValid: true,
      },
      authorData: {
        val: this.author,
        isValid: true,
      },
      formIsValid: true,
    }
  },
  computed: {
    submitBtnText() {
      if (this.mode === 'new') {
        return 'Publish'
      } else if (this.mode === 'update') {
        return 'Update'
      } else {
        return 'Broken'
      }
    },
  },
  methods: {
    async uploadImage(file) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      const response = await uploadImage(formData)
      return response.data.location
    },
    async imageUploadHandler(blobInfo, success, failure) {
      try {
        const formData = new FormData()
        formData.append('file', blobInfo.blob(), blobInfo.filename())
        const response = await uploadImage(formData)
        success(`${process.env.VUE_APP_SERVER_URL}/${response.data.location}`)
      } catch (error) {
        console.log('error', error, error.message)
        failure('HTTP Error: ', error.response.status)
      }
    },
    clearValidity(input) {
      this[input].isValid = true
    },
    validateForm() {
      this.formIsValid = true
      if (this.bodyData.val === '') {
        this.bodyData.isValid = false
        this.formIsValid = false
      }
      if (this.titleData.val === '') {
        this.titleData.isValid = false
        this.formIsValid = false
      }
      for (let i = 0; i < this.$store.getters['blog/posts'].length; i++) {
        if (this.$store.getters['blog/posts'][i].id === this.id) continue
        if (
          this.titleData.val.replace(/[\W_]+/g, '-').toLowerCase() ===
          this.$store.getters['blog/posts'][i].slug
        ) {
          this.titleData.isValid = false
          this.titleData.errorMsg =
            'The title already belongs to an existing post'
          this.formIsValid = false
        }
      }
      if (this.descriptionData.val === '') {
        this.descriptionData.isValid = false
        this.formIsValid = false
      }
      if (this.authorData.val === '') {
        this.authorData.isValid = false
        this.formIsValid = false
      }
    },
    async submitForm() {
      this.validateForm()
      if (!this.formIsValid) {
        return
      }
      let formData = {}
      if (this.mode === 'new') {
        formData = {
          body: this.bodyData.val,
          title: this.titleData.val,
          desc: this.descriptionData.val,
          categoryId: this.category.val,
          readTime: this.readingTimeData.val,
          author: this.authorData.val,
        }
      } else if (this.mode === 'update') {
        formData = {
          id: this.id,
          title: this.titleData.val,
          desc: this.descriptionData.val,
          body: this.bodyData.val,
          categoryId: this.category.val,
          readTime: this.readingTimeData.val,
          author: this.authorData.val,
          datePosted: this.datePosted,
        }
      }
      this.$emit('save-data', formData)
    },
  },
}
/*
		//id
		title
		//slug
		//category
		//tags
		description
		body
		//datePosted
		//imageUrl
		imageCaption
		imageAlt		
		readingTime
		author
	*/
</script>

<style>
.error-prompt {
  color: red;
}

.form-control .error-prompt {
  text-align: right;
}

.invalid input,
.invalid .tox-tinymce,
.invalid textarea {
  border: 1px solid red;
}
</style>
