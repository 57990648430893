<template>
  <div class="flex items-center justify-between space-x-4">
    <div v-if="isEditing" class="flex-grow">
      <input
        ref="nameInput"
        class="focus:ring-indigo-500 focus:border-indigo-500 block w-full"
        v-model="newName"
        :aria-label="`Category ${newName}`"
      />
    </div>
    <div v-else>
      {{ newName }}
    </div>
    <div class="space-x-4">
      <button
        v-show="isEditing"
        :disabled="modifyCategoryLoading"
        @click.prevent="onSaveComment"
      >
        <fa-icon v-if="modifyCategoryLoading" icon="spinner" spin />
        <fa-icon v-else icon="check" :class="$style.categoryActionIcon" />
      </button>

      <button
        v-show="isEditing"
        :disabled="modifyCategoryLoading"
        @click.prevent="onCancelEdit"
      >
        <fa-icon icon="times" :class="$style.categoryActionIcon" />
      </button>

      <button
        v-show="!isEditing"
        :disabled="deleteCategoryLoading"
        @click.prevent="isEditing = true"
      >
        <fa-icon icon="edit" :class="$style.categoryActionIcon" />
      </button>

      <button
        v-show="!isEditing"
        :disabled="deleteCategoryLoading"
        @click.prevent="initDelete"
      >
        <fa-icon v-if="deleteCategoryLoading" icon="spinner" spin />
        <fa-icon v-else icon="trash" :class="$style.categoryActionIcon" />
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from 'vue'
import slugify from 'slugify'
import { useMutation, useQueryClient } from 'vue-query'
import {
  createCategory,
  updateCategory,
  deleteCategory,
} from '@/api/categoryApi'
const queryClient = useQueryClient()
const emit = defineEmits(['deleteUnsavedComment'])
const props = defineProps({
  id: {
    type: [Number, String],
  },
  name: {
    type: String,
  },
  slug: {
    type: String,
  },
  generatedId: {
    type: [Number, String],
  },
})

const { mutate: initDeleteCategory, isLoading: deleteCategoryLoading } =
  useMutation(deleteCategory, {
    onSuccess: (_, catId) => {
      isEditing.value = false
      queryClient.setQueryData(['categories'], categories => {
        return categories.filter(category => category.id !== catId)
      })
      isEditing.value = false
    },
    onError: () => {
      alert('Could not delete the category.')
    },
  })

const { mutate: initModifyCategory, isLoading: modifyCategoryLoading } =
  useMutation(
    payload => {
      const { id } = payload
      return id ? updateCategory(payload) : createCategory(payload)
    },
    {
      onSuccess: (data, payload) => {
        queryClient.setQueryData(['categories'], categories => {
          // We have saved a new category, so we add it to the end
          // We expect a new comment id to be returned from the backend
          if (!payload.id) {
            return [
              {
                ...payload,
                id: data.id || payload.generatedId,
              },
              ...categories.slice(0, categories.length),
            ]
          }
          // We updated an already existing category
          return categories.map(category => {
            if (category.id === payload.id) {
              return {
                ...category,
                ...payload,
              }
            }

            return category
          })
        })
        isEditing.value = false
      },
      onError: () => {
        alert(`Could not ${props.id ? 'update' : 'create'} category.`)
      },
    }
  )

const newName = ref(props.name)
const isEditing = ref(false)
const nameInput = ref(null)

watch(
  isEditing,
  isEditing => {
    if (!isEditing) return
    nameInput.value.focus()
  },
  {
    flush: 'post',
  }
)

onMounted(() => {
  // New item was just added, so let's focus it.
  if (!props.id && !props.name) {
    isEditing.value = true
  }
})

const onCancelEdit = () => {
  isEditing.value = false
  if (!props.id) {
    emit('deleteUnsavedComment', props.generatedId)
  }
}

const onSaveComment = () => {
  const { id, generatedId } = props
  if (!newName.value) return
  initModifyCategory({
    id,
    generatedId,
    name: newName.value,
    slug: slugify(newName.value),
  })
}

const initDelete = () => {
  const answer = confirm('Are you sure you want to delete this category?')
  if (!answer) return

  if (!props.id) {
    // A user wants to delete a comment that was not saved in the database yet
    // For this, we only have to remove the last item from the categories in the query client
    emit('deleteUnsavedComment', props.generatedId)
    return
  }
  initDeleteCategory(props.id)
}
</script>
<style module>
.categoryActionIcon {
  @apply text-gray-500 hover:text-indigo-600 cursor-pointer transform transition-colors duration-300 text-lg;
}
</style>
