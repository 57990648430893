import api from './api'

export const fetchCategories = () => {
  return api.get('category/read.php').then(
    res =>
      res.data?.data.map(category => {
        return {
          ...category,
          name: category.name.replace('&amp;', '&'),
        }
      }) || []
  )
}

export const createCategory = payload => {
  return api.post('category/create.php', payload).then(res => res.data)
}

export const updateCategory = payload => {
  return api.post('category/update.php', payload).then(res => res.data)
}

export const deleteCategory = id => {
  return api
    .delete('category/delete.php', {
      data: {
        id,
      },
    })
    .then(res => res.data)
}
