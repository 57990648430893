<template>
  <BaseDialog title="Categories" :show="show" :fixed="false" enableFocusTrap>
    <template #header>
      <header
        class="p-6 font-semibold text-lg bg-indigo-100 text-indigo-900 text-center"
      >
        <span>Modify Categories</span>
      </header>
    </template>
    <template #default>
      <ul class="p-4 py-0 divide-y divide-gray-200" role="list">
        <li
          v-for="category of localCategories"
          :key="category.id || category.generatedId"
          class="py-4 first:pt-0 last:pb-0"
        >
          <CategoryItem
            v-bind="category"
            @deleteUnsavedComment="deleteUnsavedComment"
          />
        </li>
      </ul>
      <div class="p-4 flex justify-end">
        <button
          class="bg-indigo-100 text-indigo-600 hover:bg-indigo-200 px-4 py-2 font-semibold"
          @click.prevent="addCategory"
        >
          Add Category
        </button>
      </div>
    </template>
    <template #actions>
      <footer class="p-2">
        <div class="space-x-3">
          <BaseButton
            class="bg-indigo-600 text-indigo-100 hover:bg-indigo-700"
            @click.prevent="$emit('close')"
          >
            <span>Close</span>
          </BaseButton>
        </div>
      </footer>
    </template>
  </BaseDialog>
</template>
<script setup>
import { ref, watch } from 'vue'
import CategoryItem from './CategoryItem.vue'
import { nanoid } from 'nanoid'

defineEmits(['close'])

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  categories: {
    type: Array,
    default: () => [],
  },
})

const localCategories = ref([])

watch(
  () => props.categories,
  categories => {
    localCategories.value = [...categories]
  },
  {
    immediate: true,
  }
)

const addCategory = () => {
  localCategories.value.push({
    id: '',
    name: '',
    slug: '',
    generatedId: nanoid(),
  })
}

const deleteUnsavedComment = generatedId => {
  localCategories.value = localCategories.value.filter(
    cat => cat.generatedId !== generatedId
  )
}
</script>
